<template>
    <div>
        <b-modal
            :id="id"
            centered
            :title="title"
            ref="modal-user"
        >
            <b-form>
                <b-form-group
                    label="Nombre"
                    label-for="v-nombre"
                >
                    <b-form-input
                        id="v-nombre"
                        v-model="userDetail.name"
                        placeholder="Nombre"
                        required
                    />
                </b-form-group>
                <b-form-group label="Email:">
                    <b-form-input
                        type="email"
                        v-model="userDetail.email"
                        placeholder=""
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group label="Contraseña:">
                    <b-form-input
                        type="password"
                        v-model="userDetail.password"
                        required
                    ></b-form-input>
                </b-form-group>
                <!-- User Role -->
                <b-form-group
                    label="User Role"
                    label-for="user-role"
                >
                    <b-form-select
                        v-model="options"
                        :options="optionSelect"
                        :clearable="false"
                        input-id="user-role"
                    />
                </b-form-group>
                <b-form-group label="Estado:">
                    <b-form-checkbox
                        :checked="statusUser"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        v-model="statusUser"
                    >
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon"/>
                        </span>
                        <span class="switch-icon-right">
                            <feather-icon icon="XIcon"/>
                        </span>
                    </b-form-checkbox>
                </b-form-group>
            </b-form>
            <template #modal-footer>
                <b-button type="submit" variant="primary" @click="onSubmit">Guardar</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>

import {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BModal,
    BFormCheckbox,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    name: 'userModal',
    components: {
        BButton,
        BFormCheckbox,
        BForm,
        BFormInput,
        BFormGroup,
        BModal,
        BFormSelect,
    },
    props: {
        dataId: { type: String, default: '' },
        title: { type: String, default: '' },
        id: { type: String, default: '' },
    },
    data() {
        return {
            userDetail: {},
            statusUser: null,
            options: [
                {
                    text: '',
                    value: '',
                },
            ],
            optionSelect: [
                { value: '1', text: 'Admin' },
                { value: '2', text: 'Usuario' },
            ],
        }
    },
    methods: {
        editUser(User) {
            useJwt.getUserDetails(User)
                .then(response => {
                    this.userDetail = response.data
                })
        },
        onSubmit() {
            const self = this
            if (self.statusUser !== false) {
                self.userDetail.status = 1
            } else {
                self.userDetail.status = 0
            }
            self.userDetail.fk_role = self.options
            useJwt.getUserDetails(self.userDetail)
                .then(response => {
                    self.hideModal()
                    self.emitConfirmed()
                    self.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: response.data.message,
                            icon: 'UserIcon',
                            variant: 'success',
                            text: `Ha actualizado el usuario ${self.userDetail.name} correctamente!`,
                        },
                    })
                })
        },
        show(User = null) {
            const self = this

            if (User !== null) {
                self.userDetail = User
            } else {
                self.userDetail = {}
            }
            if (self.userDetail.status === 1) {
                self.statusUser = true
            } else {
                self.statusUser = false
            }
            self.options = self.userDetail.fk_role
            self.$bvModal.show(self.id)
        },
        hideModal() {
            this.$refs['modal-user'].hide()
        },
        emitConfirmed() {
            this.$emit('confirmed', true)
        },
    },
}

</script>
