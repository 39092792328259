<template>
    <div>
        <!-- Modal New User -->
        <user-modal
            id="modal-user"
            :title="title"
            :data-id="idUser"
            @confirmed="renderAppointments"
            ref="editarUsuario"
        ></user-modal>

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0"
        >

            <div class="m-2">

                <!-- Table Top -->
                <b-row>

                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                    </b-col>

                    <!-- Search -->
                    <b-col
                        cols="12"
                        md="6"
                    >
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="search"
                                class="d-inline-block mr-1"
                                placeholder="Buscar..."
                                @keyup="searchUsers"
                            />
                            <b-button
                                variant="primary"
                                @click="newUser()"
                            >
                                <feather-icon icon="UserPlusIcon"/>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

            </div>

            <b-table
                :stacked="options.stacked"
                class="position-relative"
                :items="users"
                striped
                responsive
                :fields="tableColumns"
                :per-page="options.perPageOptions"
                :current-page="options.currentPage"
                primary-key="id"
                show-empty
                empty-text="No matching records found"
                id="tabla-clientes"
            >

                <!-- Column: Name -->
                <template #cell(name)="row">
                    <b-media vertical-align="center">
                        <template #aside>
                            <b-avatar
                                variant="success"
                                size="32"
                                :src="row.item.name.charAt(0)"
                                :text="avatarText(row.item.name.charAt(0))"
                                v-if="row.item.status === 1"
                                @click="editUser(row)"
                            />
                            <b-avatar
                                variant="light-secondary"
                                size="32"
                                :src="row.item.name.charAt(0)"
                                :text="avatarText(row.item.name.charAt(0))"
                                v-else
                                @click="editUser(row)"
                            />
                        </template>
                        <b-link
                            @click="editUser(row)"
                            class="font-weight-bold d-block text-nowrap text-success"
                        >
                            {{ row.item.name }}
                        </b-link>
                        <small class="text-muted">{{ row.item.email }}</small>
                    </b-media>
                </template>

                <!-- Column: Date -->
                <template #cell(created_at)="row">
                    <span>{{ formatDate(row.item.created_at) }}</span>
                </template>

                <!-- Column: Role -->
                <template #cell(fk_role)="row">
                    <span class="is-admin text-success" v-if="row.item.fk_role === 1 && row.item.status === 1">
                    <feather-icon icon="ServerIcon" /> Admin
                        </span>
                    <span class="is-user text-primary"  v-if="row.item.fk_role === 2 && row.item.status === 1">
                    <feather-icon icon="UserIcon" /> Usuario
                        </span>
                </template>

                <!-- Column: Status -->
                <template #cell(status)="row">
                    <b-badge
                        pill
                        :variant="`light-success`"
                        class="text-capitalize" v-if="row.item.status === 1"
                    >
                        activo
                    </b-badge>
                    <b-badge
                        pill
                        :variant="`light-secondary`"
                        class="text-capitalize" v-if="row.item.status === 0"
                    >
                        inactivo
                    </b-badge>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="row">
                    <span class="edit-user">
                    <feather-icon icon="EditIcon" @click="editUser(row)"/>
                        </span>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Mostrando {{ options.currentPage }} de {{ this.users.length }}</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >

                        <b-pagination
                            v-model="options.currentPage"
                            :total-rows="rows"
                            :per-page="options.perPageOptions"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                            aria-controls="tabla-clientes"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BLink,
    BFormInput,
    BTable,
    BPagination,
    BButton,
    BAvatar,
    BBadge,
    BMedia,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import moment from 'moment'
import { avatarText } from '@/@core/utils/filter'
import UserModal from './UserModal.vue'

export default {
    name: 'Users',
    props: {
        idUser: {},
    },
    components: {
        BCard,
        BRow,
        BCol,
        BLink,
        BFormInput,
        BTable,
        BPagination,
        BButton,
        BBadge,
        BAvatar,
        BMedia,
        UserModal,
    },
    setup() {
        return {
            avatarText,
        }
    },
    data() {
        return {
            users: [],
            userDetail: {},
            statusUser: null,
            statusAvatar: '',
            refreshRender: true,
            title: '',
            search: '',
            setTimeoutSearch: '',
            options: {
                stacked: false,
                perPageOptions: null,
                currentPage: 1,
                rows: null,
                filter: null,
            },
            tableColumns: [
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                    tdClass: 'actionsClass',
                },
                {
                    label: 'Id',
                    key: 'id',
                    sortable: true,
                },
                {
                    label: 'Nombre',
                    key: 'name',
                    sortable: true,
                },
                {
                    label: 'Email',
                    key: 'email',
                    sortable: false,
                },
                {
                    label: 'Creado',
                    key: 'created_at',
                    sortable: false,
                },
                {
                    label: 'Rol',
                    key: 'fk_role',
                    sortable: false,
                },
                {
                    label: 'Estado',
                    key: 'status',
                    sortable: false,
                },
            ],
            showTable: false,
            showDetails: false,
            dataId: '',
        }
    },
    mounted() {
        this.getUsers()
    },
    computed: {
        rows() {
            return this.users.length
        },
    },
    methods: {
        getUsers() {
            const self = this
            useJwt.getUsers({ search: self.search })
                .then(response => {
                    self.users = response.data.users
                    self.options.perPageOptions = response.data.pagination
                })
        },
        searchUsers() {
            clearTimeout(this.setTimeoutSearch)
            this.setTimeoutSearch = setTimeout(this.getUsers, 300)
        },
        getDetailUser(user) {
            this.title = 'Editar Usuario'
            this.idModal = 'editar-usuario'
            this.newUser()
            this.userDetail = user.item
        },
        newUser() {
            this.title = 'Crear Usuario'
            this.$refs.editarUsuario.show()
        },
        editUser(User) {
            this.title = 'Editar Usuario'
            this.userDetail = User.item
            this.$refs.editarUsuario.show(this.userDetail)
        },
        hideModal() {
            this.userDetail = null // clear form values
        },
        formatDate(value) {
            return moment(value).format('DD-MM-YYYY')
        },
        renderAppointments() {
            // Remove my-component from the DOM
            this.refreshRender = false
            this.$nextTick(() => {
                // Add the component back in
                this.getUsers()
            })
            this.refreshRender = true
        },
    },
}
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style>
    .actionsClass {
        max-width: 10px;
    }
    .edit-user:hover {
        cursor: pointer;
    }
</style>
